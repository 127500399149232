import api from "../../axios/api.js";

export class LicenceTypesService {
  async getLicenceTypes() {
    const data = await api.get("LicenceTypes");
    return data;
  }

  async postLicenceTypes(body) {
    const data = await api.post("LicenceTypes", body);
    return data;
  }

  async putLicenceTypes(licenceType, body) {
    console.table(licenceType);
    const data = await api.put(`LicenceTypes/${licenceType}`, body);
    return data;
  }

  async deleteLicenceTypesType(licenceType) {
    const data = await api.delete(`LicenceTypes/${licenceType}`);
    return data;
  }
}
